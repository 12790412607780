<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-01-16 10:46:06
 * @FilePath: /mediatom-web/src/views/mediadashboard/components/siderCharts/positionPie/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-pie-plat-chart-container">
    <a-spin :spinning="spinning">
      <div class="chart">
        <Chart :option="options" />
      </div>
    </a-spin>
  </div>
</template>

<script>
import HomeTitle from '../../homeTitle.vue'
import optionTemp from './options'
import { numFormat } from '@/utils/dealNumber'
import { getPlatPieData } from '@/api/mediaDashboard'
import Chart from '@/components/common/Chart'
import * as echarts from 'echarts'
export default {
  components: { HomeTitle, Chart },
  data () {
    return {
      spinning: false,
      options: {},
      query: {
        dateStart: '',
        dateEnd: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    numFormat,
    async getData () {
      const { data = {} } = await getPlatPieData()
      const { list = [], unitRevenueSum = 0 } = data
      const option = JSON.parse(JSON.stringify(optionTemp))
      const sum = unitRevenueSum
      option.legend.formatter = (name, a) => {
        const uItem = list.filter((item) => item.platName === name)[0].unitRevenue
        const propor = uItem / sum

        var arr = ['{a|' + name + '}', '{b|' + (sum === 0 ? 0 : (propor * 100).toFixed(2)) + '%}']
        return arr.join('  ')
      }
      option.legend.textStyle = {
        // 添加
        // padding: [8, 0, 0, 0],
        rich: {
          // y颜色种类介绍
          a: {
            fontSize: 12,
            width: 60,
            color: '#888'
          },
          b: {
            fontSize: 12,
            width: 70
          }
        }
      }
      // 格式化
      option.tooltip.formatter = (params) => {
        return (
          params.marker +
          '<span>&nbsp;</ span>平台：' +
          (params.name || '其他') +
          '<div>&nbsp;&nbsp;&nbsp;&nbsp;</ div>收益：' +
          numFormat(params.data.value, 3, '') +
          '<div>&nbsp;&nbsp;&nbsp;&nbsp;</ div>占比：' +
          (!params.data.value ? 0 : sum === 0 ? 0 : ((params.data.value / sum) * 100).toFixed(2)) +
          '%'
        )
      }
      option.tooltip.position = (point, params, dom, rect, size) => {
        var x = 0 // x坐标位置
        var y = 0 // y坐标位置
        // 当前鼠标位置
        var pointX = point[0]
        var pointY = point[1]
        // 提示框大小
        var boxWidth = size.contentSize[0]
        var boxHeight = size.contentSize[1]

        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = pointX + 10
        } else {
          // 左边放的下
          x = pointX - boxWidth - 10
        }

        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5
        } else {
          // 上边放得下
          y = pointY - boxHeight
        }
        return [x, y]
      }
      option.series[0].itemStyle = {
        borderRadius: [5, 5, 5, 5],
        borderColor: '#fff',
        borderWidth: 2,
        color: (params) => {
          const colorList = [
            {
              c1: 'rgba(183, 105, 247, 1)',
              c2: 'rgba(138, 89, 251, 1)'
            },
            {
              c1: 'rgba(95, 233, 196, 1)',
              c2: 'rgba(62, 219, 223, 1)'
            },
            {
              c1: 'rgba(249, 169, 110, 1)',
              c2: 'rgba(248, 123, 104, 1)'
            },
            {
              c1: 'rgba(250, 84, 124, 1)',
              c2: 'rgba(247, 65, 108, 1)'
            },
            {
              c1: 'rgba(22, 178, 246, 1)',
              c2: 'rgba(31, 125, 238, 1)'
            },
            {
              c1: 'rgba(248, 202, 116, 1)',
              c2: 'rgba(252, 159, 74, 1)'
            }
          ]
          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              // 左、下、右、上
              offset: 0,
              color: colorList[params.dataIndex].c1
            },
            {
              offset: 1,
              color: colorList[params.dataIndex].c2
            }
          ])
        }
      }
      option.series[0].data = list
        ? list.map((item, i) => {
            return {
              value: item.unitRevenue,
              name: item.platName || '其他'
            }
          })
        : [
            {
              value: 0,
              name: ''
            }
          ]
      this.options = option
    }
  }
}
</script>
<style lang="less">
@import '~@/assets/less/mixins/redefineAnt.less';
.media-pie-plat-chart-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  .ant-spin-nested-loading {
    flex-grow: 1;
    height: 0;
    position: relative;
    .ant-spin-container {
      height: 100%;
      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.media-pie-plat-chart-container {
  .aRadioBtn();
}
</style>
